import * as React from "react"
import Seo from "../../components/seo"
import AppLayout from "../../components/app/app-layout"

const title = "特定商取引法に基づく表記";

const TokushohoContent = () => {
  return (
      <article className="container page-article-main">
      <h1>{title}</h1>

      <section>
        <table className="table company-info">
          <tbody>
            <tr>
              <th>事業者名</th>
              <td>株式会社DanRan / DanRan Co.,Ltd.</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>
                〒103-0005
                <br/>
                東京都中央区日本橋久松町4-7
                <br/>
                グリームス日本橋8F
              </td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>田中 智章</td>
            </tr>
            <tr>
              <th>お問い合わせ</th>
              <td>設定画面の「お問い合わせ」よりご連絡ください</td>
            </tr>
            <tr>
              <th>利用料金</th>
              <td>本アプリまたは当社サイト上で表示している有料サービス利用額（利用規約に定めます）</td>
            </tr>
            <tr>
              <th>利用料金以外に必要となる費用</th>
              <td>本アプリの利用および当社サイト等を閲覧いただくために必要な機器やインターネット接続環境の費用ならびに通信料金等はお客様の負担となります。それぞれの料金は、ご利用中のインターネットプロバイダーまたは携帯電話会社にお問い合わせください。
              </td>
            </tr>
            <tr>
              <th>支払時期</th>
              <td>各アプリストアの定める支払い時期に「もっと！あかまるコース」1ヵ月分の月額料金をお支払いいただきます。<br />
                本有料コースのご利用期間は、有料コースの更新日の24時間前までに所定の方法によりご解約いただかない限り、更新日から1か月毎の期間にて自動的に更新されます。<br/><br />
                クレジットカードでお支払いの場合、実際の引落時期は各カード会社の規定によります。
              </td>
            </tr>
            <tr>
              <th>支払方法</th>
              <td>各アプリストア内の決済となります</td>
            </tr>
            <tr>
              <th>返品</th>
              <td>商品の特性上、お支払い完了後の返品は承っておりません</td>
            </tr>
            <tr>
              <th>サービス開始時期</th>
              <td>料金が決済され、有料会員登録完了後、即時にサービス利用が可能になります</td>
            </tr>
            <tr>
              <th>サービス解約</th>
              <td>利用規約に定めます</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h2>スマートフォン動作環境</h2>
        <table className="table company-info">
          <tbody>
            <tr>
              <th>OS</th>
              <td>iOS 13以上 <br />
                  Android 9.0以上
              </td>
            </tr>
            <tr>
              <th>ブラウザ</th>
              <td>各OSの標準ブラウザ</td>
            </tr>
          </tbody>
        </table>

        <p>注意事項</p>
        <ul>
          <li>PCおよびフィーチャーフォンには対応しておりません。</li>
          <li>JavaScript、Cookiesを有効にしてご利用ください。</li>
          <li>回線速度により、動作が遅く感じることがあります。</li>
        </ul>
      </section>

      <section>
        <h2>附則</h2>
        <p>制定・施行：2022年9月1日 / 改訂： 2022年9月1日</p>
      </section>

      <section>
        <h2>特定商取引法に基づく表記に関する連絡先</h2>
        <p>
        株式会社DanRan / info@dan-ran.co.jp
        </p>
      </section>
    </article>
  );
}

const Tokushoho = () => {
  return (
    <AppLayout isShownHeader={false}>
      <Seo title={title} isIndex={false}/>
      <TokushohoContent/>
    </AppLayout>
  );
}

export default Tokushoho
